.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000000000;
}

.popupRoot {
    position: fixed;
}
